import clsx from "clsx";

type Props = React.HTMLAttributes<HTMLUListElement> & {
  single?: boolean;
};

export default function List({ children, className, single, ...rest }: Props) {
  return (
    <ul className={clsx("!mb-0", single && "!ps-6", className)}>{children}</ul>
  );
}
